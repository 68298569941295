import { Endpoints } from './constants';
import type { TenantLookupResponse } from './types';
import { fetcher } from './utils';

const lookupTenant = async (userEmail: string, workflowId?: string): Promise<TenantLookupResponse> => {
  return fetcher(
    Endpoints.TENANT_LOOKUP_BY_DOMAIN,
    {
      method: 'POST',
      body: JSON.stringify({ userEmail }),
      headers: {
        'Impr-Workflow-Id': workflowId || '',
      },
    },
    false,
  );
};

const getClientUserSession = async (workflowId: string) => {
  return fetcher(
    Endpoints.GET_CLIENT_USER_SESSION,
    {
      method: 'POST',
      headers: {
        'Impr-Workflow-Id': workflowId,
      },
    },
    false,
  );
};

export { lookupTenant, getClientUserSession };
