import { InputBox } from '@imprivata-cloud/components';
import { Form, type GetProp, Upload } from 'antd';
import type { UploadProps } from 'antd/lib';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as api from '../../../api/setupServices';
import UploadImg from '../../../assets/upload.svg?react';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import './OrganizationRoute.less';
import { useSearchParams } from 'react-router-dom';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { setToLocalStorage } from '../../../utils/utils';
import { STEPS, TRANSLATION_BUTTON } from '../../constants';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

// NOTE: names are cased normal because the antd error message uses them
// in future we can come up with some custom error message with i18n support.
const ORGANIZATION_NAME_FIELD_NAME = 'organization name';
const EMAIL_FIELD_NAME = 'organization email';
const IMAGE_URL_FIELD_NAME = 'image url';
interface FormValues {
  [ORGANIZATION_NAME_FIELD_NAME]: string;
  [EMAIL_FIELD_NAME]: string;
  [IMAGE_URL_FIELD_NAME]: string;
}

const TRANSLATION_ROOT = 'setup.org-info.content';

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const OrganizationRoute = () => {
  const [shouldContinue, setShouldContinue] = useState<boolean>(false);
  const [data, setData] = useState<FormValues>();
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { emitError } = useNotifications();

  const onDataFinished = (values: FormValues) => {
    setShouldContinue(true);
    setData(values);
  };

  const onVerify = useCallback(async () => {
    try {
      if (data) {
        console.log(data[EMAIL_FIELD_NAME]);
        setToLocalStorage('orgEmail', data[EMAIL_FIELD_NAME]);
        await api
          .organizationInfoSave({
            logoUrl: data[IMAGE_URL_FIELD_NAME],
            orgName: data[ORGANIZATION_NAME_FIELD_NAME],
          })
          .then(() => {
            navigate({ pathname: `/setup/${STEPS.IDP_CHOICE}`, search: `?${searchParams.toString()}` });
          })
          .catch((e) => {
            emitError(new AppError(e));
          });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log(`Form validation failed: ${error.message}`);
      } else {
        console.log('Unexpected error:', error);
      }
    }
  }, [data, emitError, navigate, searchParams]);

  const onBack = () => {
    setShouldContinue(false);
    data && form.setFieldsValue(data);
  };

  return shouldContinue ? (
    <OrganizationVerify data={data} goBack={onBack} onVerify={onVerify} />
  ) : (
    <OrganizationInfo onFinished={onDataFinished} data={data} />
  );
};

const OrganizationInfo = ({ onFinished, data }: { onFinished: (values: FormValues) => void; data?: FormValues }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const { emitError } = useNotifications();

  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as FileType, (url) => {
        setImageUrl(url);
      });
      form.setFieldsValue({
        [IMAGE_URL_FIELD_NAME]: URL.createObjectURL(info.file.originFileObj as FileType),
      });
    } else if (info.file.status === 'error') {
      setImageUrl(undefined);
    }
  };

  const beforeUpload = (file: FileType) => {
    return new Promise<FileType>((resolve, reject) => {
      const isFileTypeOk = file.type.startsWith('image');
      if (!isFileTypeOk) {
        emitError(new AppError({ code: ErrorCode.INVALID_FILE_TYPE }));
        setImageUrl(undefined);
        reject(false);
        return;
      }

      const isFileSizeOk = file.size / 1024 <= 100; // <= 100KB
      if (!isFileSizeOk) {
        emitError(new AppError({ code: ErrorCode.LOGO_UPLOAD_ERROR }));
        setImageUrl(undefined);
        reject(false);
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const image = new Image();
        image.src = e?.target?.result as string;
        image.onload = () => {
          const height = image.height;
          const width = image.width;
          if (height > 100 || width > 200) {
            emitError(new AppError({ code: ErrorCode.LOGO_UPLOAD_ERROR }));
            setImageUrl(undefined);
            reject(false);
          } else {
            resolve(file);
          }
        };
      };
      fileReader.readAsDataURL(file);
    });
  };
  const logoPreview = (imageUrl: string | undefined) =>
    imageUrl ? (
      <img src={imageUrl} alt="organization logo" className="upload-preview" />
    ) : (
      <div className="upload-preview">
        <UploadImg />
        &nbsp;
        <span>{t(`${TRANSLATION_ROOT}.logo-upload-sub-label`)}</span>
      </div>
    );

  // we need this to re-populate form with data when user goes back
  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setImageUrl(data[IMAGE_URL_FIELD_NAME]);
    }
  }, [data, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinished}>
      <div className={'subtitle'} />

      {/* Organization Name */}
      <Form.Item name={ORGANIZATION_NAME_FIELD_NAME} rules={[{ required: true }]}>
        <InputBox label={t(`${TRANSLATION_ROOT}.name-label`)} required autoFocus />
      </Form.Item>

      {/* Upload Image */}
      <Form.Item
        name={IMAGE_URL_FIELD_NAME}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Upload
          accept="image/*"
          customRequest={({ onSuccess }) => onSuccess?.('ok')}
          maxCount={1}
          showUploadList={false}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          className="upload-container"
        >
          <label className="logo-upload-label">{t(`${TRANSLATION_ROOT}.logo-label`)}</label>
          {logoPreview(imageUrl)}
        </Upload>
      </Form.Item>

      {/* Organization Email */}
      <Form.Item
        name={EMAIL_FIELD_NAME}
        extra={t(`${TRANSLATION_ROOT}.email-sub-label`)}
        rules={[{ required: true, type: 'email' }]}
      >
        <InputBox label={t(`${TRANSLATION_ROOT}.email-label`)} required />
      </Form.Item>

      {/* Continue */}
      <ContinueButton
        label={t(TRANSLATION_BUTTON)}
        htmlType="submit"
        disabled={!imageUrl}
        validateDirty={[EMAIL_FIELD_NAME, ORGANIZATION_NAME_FIELD_NAME]}
      />
    </Form>
  );
};

const OrganizationVerify = ({
  data,
  goBack,
  onVerify,
}: { data?: FormValues; goBack: () => void; onVerify: () => void }) => {
  const { t } = useTranslation();
  const TRANSLATION_ROOT = 'setup.org-info-confirm';

  return (
    <div>
      <p>{t(`${TRANSLATION_ROOT}.content.label`)}</p>
      <p>{t(`${TRANSLATION_ROOT}.content.name-logo-label`)}</p>
      <h2>{data?.[ORGANIZATION_NAME_FIELD_NAME]}</h2>
      {/* img height can be removed after proper error handling for image upload will be implemented */}
      <img src={data?.[IMAGE_URL_FIELD_NAME]} alt="" style={{ height: '150px' }} />
      <p>{t(`${TRANSLATION_ROOT}.content.domain-name-label`)}</p>
      <h3>{data?.[EMAIL_FIELD_NAME]?.split('@')?.pop()}</h3>
      <ContinueButton secondaryButton={{ label: t('common.back-button'), onClick: goBack }} onClick={onVerify}>
        {t('common.continue-button')}
      </ContinueButton>
    </div>
  );
};

export default OrganizationRoute;
