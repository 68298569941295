import { Trans, useTranslation } from 'react-i18next';
import { _getEnv } from '../../../api/utils';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { STEPS } from '../../constants';

const TRANSLATION_ROOT = `setup.${STEPS.SETUP_COMPLETE}.content`;
const TRANSLATION_BUTTON = 'common.continue-imprivata-access';

const SetupComplete = () => {
  const { t } = useTranslation();
  const { currentEnv, tld } = _getEnv();

  const handleGoToImprivataAccess = () => {
    localStorage.clear();
    window.location.href = `https://access.app.${currentEnv}.imprivata.${tld}/`;
  };

  return (
    <>
      <p style={{ marginBottom: '8.13rem' }}>
        <Trans i18nKey={`${TRANSLATION_ROOT}.description`} />
      </p>
      <ContinueButton label={t(TRANSLATION_BUTTON)} onClick={handleGoToImprivataAccess} />
    </>
  );
};

export default SetupComplete;
