import { Button } from '@imprivata-cloud/components';
import ProductCardPattern from '../../../assets/product_card_pattern.svg?react';
import classes from './UnpurchasedProductCard.module.less';

const defaultBG = '#564D87';

type UnpurchasedProductCardProps = {
  title: string;
  color?: string;
};

export const UnpurchasedProductCard = ({ title, color = defaultBG }: UnpurchasedProductCardProps) => {
  return (
    <div className={classes.card} style={{ backgroundColor: color }}>
      <div className={classes.titleContainer}>
        <ProductCardPattern style={{ position: 'absolute', top: 0, right: 0 }} />
        <div className={classes.title}>{title}</div>
      </div>
      <div className={classes.linkContainer}>
        <Button className={classes.link} label={'Learn more'} size={'small'} type={'text'} />
      </div>
    </div>
  );
};
