// Copyright 2024, Imprivata, Inc.  All rights reserved.

export const Endpoints = {
  START_TENANT_SETUP: '/am-portal-setup-svc/tenant-setup/v1/start',
  ICP_METADATA_SAVE: '/am-portal-setup-svc/icp-metadata-admin/v1/idp/save',
  ORG_PREFERENCES_UPDATE: '/am-portal-setup-svc/preferences/v1/org-preferences/update',
  AAD_CONSENT_URL: '/am-portal-setup-svc/tenant-setup/v1/aad-consent-url/get',
  COMPLETE_SETUP: '/am-portal-setup-svc/tenant-setup/v1/complete',
  TENANT_LOOKUP_BY_DOMAIN: '/am-portal-svc/tenant-lookup/v1/tenants-by-domain/get',
  GET_CLIENT_USER_SESSION: '/am-portal-svc/session/v1/client-user-session/get',
} as const;

export const CommonHeaders = {
  'Content-Type': 'application/json',
  'Strict-Transport-Security': 'max-age=86400;includeSubDomains',
  'X-XSS-Protection': '1;mode=block',
  'X-Content-Type-Options': 'nosniff',
  'X-Frame-Options': 'DENY',
};

export const IdpChoices = {
  ENTRA_ID_VALUE: 'entraId',
  EXTERNAL_VALUE: 'external',
} as const;
