import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as api from '../../../api/setupServices';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError, FatalError } from '../../../errorHandler/errors';

const TRANSLATION_ROOT = 'setup.content';

const SetupLandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { emitError } = useNotifications();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const startSetup = async () => {
      const bearerToken = searchParams.get('bt');
      await api.startTenantSetup(bearerToken);
    };
    setLoading(true);
    startSetup()
      .then(() => {
        console.log('Tenant setup started');
        setLoading(false);
      })
      .catch((e) => {
        console.log('Tenant setup failed', e);
        setLoading(false);
        if (e.code === ErrorCode.INVALID_TENANT_STATE && e.data && e.data?.TENANT_STATE === 'ENABLED') {
          e.code = ErrorCode.LINK_DEAD;
          emitError(new AppError(e));
        } else {
          emitError(new FatalError(e));
        }
      });
  }, [searchParams, emitError]);

  const onSubmit = () => {
    // Feature flag until we have full implementation
    if (searchParams.get('dpa')) {
      navigate({ pathname: '/setup/data-processing-addendum', search: `?${searchParams.toString()}` });
    } else {
      navigate({ pathname: '/setup/org-info', search: `?${searchParams.toString()}` });
    }
  };

  return loading ? (
    <></>
  ) : (
    <>
      <div>
        <p className={'subtitle'}>{t(`${TRANSLATION_ROOT}.description`)}</p>
      </div>
      <div>
        <span className={'primary-body'}>
          <ul>
            <li>{t(`${TRANSLATION_ROOT}.list-content-1`)}</li>
            <li>{t(`${TRANSLATION_ROOT}.list-content-2`)}</li>
            <li>{t(`${TRANSLATION_ROOT}.list-content-3`)}</li>
          </ul>
        </span>
      </div>
      <div style={{ marginTop: '2rem' }}>
        <Form>
          <ContinueButton
            autoFocus
            data-testid="proceed-btn"
            label={t(`${TRANSLATION_ROOT}.next-button`)}
            onClick={onSubmit}
            type="primary"
          />
        </Form>
      </div>
    </>
  );
};

export default SetupLandingPage;
