import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sendTenantSetupCompleteRequest } from '../../../api/setupServices';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { STEPS, TRANSLATION_BUTTON } from '../../constants';

const TRANSLATION_ROOT = `setup.${STEPS.UPDATE_CONDITIONAL_POLICIES}.content`;

const UpdateConditionalPolicies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  // coming from Entra ID redirect
  const error = new URLSearchParams(window.location.search).get('error');

  if (error) {
    return <h1>{error}</h1>;
  }
  const handleNextStep = async () => {
    const isVerified = await sendTenantSetupCompleteRequest(true);
    if (isVerified) {
      navigate({ pathname: '/setup/setup-complete', search: `?${searchParams.toString()}` });
    } else {
      console.error('Verification failed, cannot proceed');
    }
  };

  return (
    <>
      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.description`} />
        &nbsp;
        <a href="https://example.com" target="_blank" rel="noopener noreferrer">
          {t(`${TRANSLATION_ROOT}.see-instructions`)}
        </a>
      </p>

      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.conditional-access`} />
      </p>

      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.exclude-imprivata-entra-id`} />
      </p>

      <ContinueButton label={t(TRANSLATION_BUTTON)} onClick={handleNextStep} />
    </>
  );
};

export default UpdateConditionalPolicies;
