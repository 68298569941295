import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getClientUserSession } from '../../api/portalServices';
import type { GetClientUserSessionResponse } from '../../api/types';
import { PortalLanding } from './landing/PortalLanding';
import { PortalLogin } from './login/PortalLogin';

const PortalHome = () => {
  const [userSessionData, setUserSessionData] = useState<GetClientUserSessionResponse>({});
  const [workflowId, setWorkflowId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const wid = uuidv4();
    const checkUserSession = async () => {
      // call getClientUserSession to check if the user is logged in
      return await getClientUserSession(wid || '');
    };
    setWorkflowId(wid);
    checkUserSession()
      .then((response) => {
        setIsLoading(false);
        if (response) {
          setUserSessionData(response);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        // Log error and go to the login page.
        console.error('Error fetching user session:', e);
      });
  }, []);

  console.debug('[PortalHome] workflowId:', workflowId);

  if (isLoading) {
    return <></>;
  }

  const pageToRender = userSessionData?.tenantId ? <PortalLanding /> : <PortalLogin workflowId={workflowId} />;
  return <>{pageToRender}</>;
};

export default PortalHome;
