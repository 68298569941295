// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { LocaleProvider } from './i18n';
import SetupRoutes from './setup/SetupRoutes';
import './styles/index.less';
import PortalRoutes from './portal';

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

// App entrypoint
const App = () => {
  const title = useMemo(() => {
    if (window.location.pathname.startsWith('/setup')) {
      return 'Setup';
    }
    return 'Portal';
  }, []);

  return (
    <Routes>
      <Route path={'setup/*'} element={<SetupRoutes />} />
      <Route path="*" element={<PortalRoutes />} />
    </Routes>
  );
};

// Render the application
root.render(
  <React.StrictMode>
    <LocaleProvider>
      <Router>
        <App />
      </Router>
    </LocaleProvider>
  </React.StrictMode>,
);
