import { ImprUserDropdown } from '@imprivata-cloud/components';
import { Typography } from 'antd';
import ImprivataLogo from '../../../assets/Imprivata_Logo.svg?react';
import { PurchasedProductCard } from '../../../components/landing/PurchasedProductCard/PurchasedProductCard';
import { UnpurchasedProductCard } from '../../../components/landing/UnpurchasedProductCard/UnpurchasedProductCard';
import PageFooter from '../../../components/layout/PageFooter';
import PageHeader from '../../../components/layout/PageHeader';
import './PortalLanding.less';

export const PortalLanding = () => {
  return (
    <div className="landing-page">
      <PageHeader
        title={'Imprivata Access Management'}
        rightSlot={<ImprUserDropdown username="John Doe" email="lorem@ipsum.cloud" onLogout={() => {}} />}
        className={'page-header'}
      />
      <div className="page-header-container">
        <div className={'page-header'}>CUSTOMER EXPERIENCE CENTER</div>
      </div>
      <div className="content-container">
        <div className="section">
          <div className={'iam-product-summary-container'}>
            <div>
              <h1>Imprivata Access Management</h1>
              <Typography.Text type="secondary">You have purchased 2 products</Typography.Text>
            </div>
            <div>
              <ImprivataLogo style={{ width: '100px', height: '100px', fill: 'black' }} />
            </div>
          </div>
        </div>
        <div className="section fluid">
          <PurchasedProductCard productName="Advanced Intelligence Platform" />
          <PurchasedProductCard productName="Mobile Access Management" />
        </div>
        <div className="section fluid">
          <UnpurchasedProductCard title="Enterprise Access Management" />
          <UnpurchasedProductCard title="Patient Access" color="#1F9589" />
        </div>
      </div>
      <PageFooter />
    </div>
  );
};
