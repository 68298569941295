import { CommonHeaders } from '../api/constants';
import { _getEnv } from '../api/utils';

export const setToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key) as string;
};

// Used to turn the path into a key for translations and graphics
//
// TODO: Fix this monstrosity
export const getPathKey = (route: string) => {
  if (route.length === 0) {
    return '';
  }

  let updatedRoute = route;

  if (updatedRoute.endsWith('/')) {
    updatedRoute = updatedRoute.slice(0, -1);
  }

  return updatedRoute.slice(1).replaceAll('/', '.');
};

export const getSPMetadataURL = () => {
  const { currentEnv } = _getEnv();
  const tenantId = new URLSearchParams(window.location.search).get('tenantId') || 'no-tenant-id';
  return `https://metadata.app.${currentEnv}.imprivata.cloud/${tenantId}/saml_metadata/auth_sign_saml_metadata.xml`;
};

export const getIDPUrl = (tenantId: string, workflowId: string) => {
  const { currentEnv, tld } = _getEnv();
  const idpUrl = `https://${tenantId}.sys.${currentEnv}.imprivata.${tld}/idp-web/idp/authenticate`;
  const targetUrl = `https://access.app.${currentEnv}.imprivata.${tld}`;
  const idpQueryParams = `?workflowId=${workflowId}&target=${targetUrl}`;

  return `${idpUrl}${idpQueryParams}`;
};
