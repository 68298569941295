import { ErrorCode } from '../errorHandler/constants';
import { FatalError } from '../errorHandler/errors';
import { Endpoints } from './constants';
import type {
  GetAadConsentRequest,
  GetAadConsentResponse,
  IdpMetadataSaveRequest,
  OrgPreferencesUpdateRequest,
  TenantSetupCompleteRequest,
} from './types';
import { fetcher } from './utils';

function icpMetadataSave(data: IdpMetadataSaveRequest) {
  return fetcher(Endpoints.ICP_METADATA_SAVE, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

function organizationInfoSave(data: OrgPreferencesUpdateRequest) {
  return fetcher(Endpoints.ORG_PREFERENCES_UPDATE, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

// TODO: We need to know how to get these from BE
const getIpRanges = async (): Promise<string[]> => {
  return ['44.207.16.175/32', '44.196.189.191/32', '34.196.47.118/32'];
};

const getAadConsentUrl = async (data: GetAadConsentRequest): Promise<GetAadConsentResponse> => {
  return fetcher(Endpoints.AAD_CONSENT_URL, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

const createTenantSetupCompleteRequest = (withEntraId: boolean): TenantSetupCompleteRequest | null => {
  const email = localStorage.getItem('orgEmail');

  if (!email) {
    console.log('Email not found in localStorage');
    throw new FatalError({ code: ErrorCode.FATAL_ERROR, message: 'Email not found in localStorage' });
  }

  return {
    email: email,
    withEntraId: withEntraId,
  };
};

const sendTenantSetupCompleteRequest = async (withEntraId: boolean): Promise<boolean> => {
  const request = createTenantSetupCompleteRequest(withEntraId);
  return fetcher(Endpoints.COMPLETE_SETUP, {
    method: 'POST',
    body: JSON.stringify(request),
  });
};

const startTenantSetup = async (bearerToken: string | null) => {
  if (!bearerToken) {
    throw new Error('Bearer token is missing');
  }
  return fetcher(Endpoints.START_TENANT_SETUP, {
    method: 'POST',
    body: JSON.stringify({ bearerToken: bearerToken }),
  });
};

export {
  icpMetadataSave,
  getIpRanges,
  organizationInfoSave,
  getAadConsentUrl,
  startTenantSetup,
  createTenantSetupCompleteRequest,
  sendTenantSetupCompleteRequest,
};
