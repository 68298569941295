// import { Modal } from 'antd';
import React, { useState, useCallback, type UIEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataProcessingAddendum, { DPA_DOCUMENT_NAME } from './DataProcessingAddendum';
import { Button } from '@imprivata-cloud/components';
import './DataProcessingAddendumPage.less';
import QuestionSvg from '../../../assets/icons/question.svg?react';
import { Modal } from 'antd';

const TRANSLATION_ROOT = 'setup.data-processing-addendum.content';
const SESSION_STORAGE_KEY_DPA = 'DPA_DOCUMENT_NAME';

const DataProcessingAddendumPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // Will use this later when we have API call
  const [loading] = useState(false);

  const onSubmit = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEY_DPA, DPA_DOCUMENT_NAME)
    navigate({ pathname: '/setup/org-info', search: `?${searchParams.toString()}` });
  };

  const onDecline = () => {
    navigate({ pathname: '/setup', search: `?${searchParams.toString()}` });
  };

  const [visibleYouMust, setVisibleYouMust] = useState(false);
  const openYouMust = () => setVisibleYouMust(true);
  const closeYouMust = () => setVisibleYouMust(false);

  const [bottomReached, setBottomReached] = useState(false);

  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    const target: HTMLDivElement = e.target as HTMLDivElement;
    if (
      target.scrollHeight - target.scrollTop <=
      target.clientHeight + 1 // +1 to compensate fractions
    ) {
      setBottomReached(true);
    }
  }, []);

  return loading ? (
    <></>
  ) : (
    <>
      <div className="dpa-text-container" onScroll={handleScroll}>
        <DataProcessingAddendum/>
      </div>
      <div className="dpa-footer">
        <Button
          label={t(`${TRANSLATION_ROOT}.agree`)}
          disabled={!bottomReached}
          type="primary"
          data-testid="dpa-agree-btn"
          onClick={onSubmit}
        />
        <Button
          label={t(`${TRANSLATION_ROOT}.decline`)}
          data-testid="dpa-decline-btn"
          onClick={openYouMust}
        />
      </div>
      <Modal
        open={visibleYouMust}
        destroyOnClose
        closable={false}
        footer={null}
      >
        <div className="modal-you-must">
          <div style={{ margin: '.5rem' }}>
            <QuestionSvg style={{ width: '4rem' }}/>
          </div>
          <h1>{t(`${TRANSLATION_ROOT}.decline-modal-title`)}</h1>
          <div>{t(`${TRANSLATION_ROOT}.decline-modal-text`)}</div>
          <div className="footer">
            <Button
              label={t(`${TRANSLATION_ROOT}.go-back`)}
              type="primary"
              data-testid="dpa-you-must-modal-agree-btn"
              onClick={closeYouMust}
            />
            <Button
              label={t(`${TRANSLATION_ROOT}.decline`)}
              data-testid="dpa-you-must-modal-decline-btn"
              onClick={onDecline}
            />
          </div>
        </div>
      </Modal>

    </>
  );
};

export default DataProcessingAddendumPage;
