import './PortalLogin.less';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoginComponent } from '../../../components/login/LoginComponent';
import Separator from '../../../components/separator/Separator';
import './PortalLogin.less';
import { lookupTenant } from '../../../api/portalServices';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { getIDPUrl } from '../../../utils/utils';

export type PortalLoginProps = {
  workflowId?: string;
};

const PortalLogin = ({ workflowId }: PortalLoginProps) => {
  const { emitError } = useNotifications();
  const { t } = useTranslation();

  console.log('[PortalLogin] workflowId:', workflowId);

  const onLogin = async (email: string) => {
    // Lookup tenant
    await lookupTenant(email, workflowId)
      .then((response) => {
        if (response?.tenantIds && response.tenantIds.length > 0) {
          console.log('Redirect to IDP');
          //Construct IDP URL and redirect to IDP for authentication
          const idpUrl = getIDPUrl(response.tenantIds[0], workflowId || '');
          console.log('IDP URL:', idpUrl);
          window.location.href = idpUrl;
        }
      })
      .catch((e) => {
        emitError(new AppError(e));
      });
  };

  return (
    <div className="portal-window elevation-xl p-xxxl">
      <Row className="portal-container">
        <Col xs={0} sm={0} md={12} lg={14} className="portal-info">
          <div className="portal-info-steps">
            <h1>{t('portal.info.title')}</h1>
            <Separator />
            <p className={'portal-info-text'}>{t('portal.info.steps')}</p>
          </div>
          <PortalFooter />
        </Col>

        <Col xs={24} sm={24} md={12} lg={10}>
          <LoginComponent onSubmit={onLogin} />
        </Col>
      </Row>
    </div>
  );
};

export { PortalLogin };

const PortalFooter = () => {
  return (
    <div className={'portal-info-footer'}>
      <p>© 2025 Imprivata, Inc. All rights reserved.</p>
    </div>
  );
};
