// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Col, Row } from 'antd';
import './SetupPageLayout.less';
import type React from 'react';
import type { PropsWithChildren } from 'react';
import PageFooter from '../../components/layout/PageFooter';

type SetupPageLayoutProps = {
  title: string;
  graphic?: React.ReactNode;
};

const SetupPageLayout = ({ title, graphic, children }: PropsWithChildren<SetupPageLayoutProps>) => {
  return (
    <div className="setup-window p-xxxl elevation-xl">
      <div className="setup-container">
        <Row>
          <Col sm={24} md={24} lg={graphic ? 14 : 24} className="setup-content p-xxxl">
            <h1 className="title">{title}</h1>
            {children}
          </Col>
          <Col sm={0} md={0} lg={graphic ? 10 : 0}>
            <div className="setup-graphic">
              <div>{graphic}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={'setup-footer'}>
        <PageFooter />
      </div>
    </div>
  );
};

export { SetupPageLayout };
