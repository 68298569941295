import React from 'react';
import './LoginComponent.less';
import { InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useTranslation } from 'react-i18next';
import ImprivataLogo from '../../assets/Imprivata_Logo.svg?react';
import HexagonLogo from '../../assets/hexagon_user.svg?react';
import ContinueButton from '../continueButton/ContinueButton';

export type LoginProps = {
  onSubmit: (userEmail: string) => void;
};

const LoginComponent = ({ onSubmit }: LoginProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const submitLogin = () => {
    console.log('submitLogin');
    form.validateFields().then((values) => {
      onSubmit(values?.userEmail ? values.userEmail : '');
    });
  };

  return (
    <div className={'login-container'}>
      <div className={'login-header'}>
        <HexagonLogo className={'login-header-hexagon'} />
      </div>
      <div className={'login-form'}>
        <Form form={form} className={'login-form-items'} requiredMark={false} onFinish={submitLogin}>
          <h1 className={'login-form-title'}>{t('portal.login.title')}</h1>
          <FormItem
            name={'userEmail'}
            rules={[
              {
                required: true,
                message: t('portal.login.email-required'),
              },
              {
                type: 'email',
                message: t('portal.login.email-invalid'),
              },
            ]}
            colon={false}
            className={'login-form-input'}
          >
            <InputBox size="large" data-testid="email-input" label={t('portal.login.email-input')} autoFocus />
          </FormItem>
          <ContinueButton
            label={t('common.continue-button')}
            type="primary"
            block
            validateDirty={['userEmail']}
            htmlType={'submit'}
          />
        </Form>
        <ImprivataLogo fill={'#2C3F57'} className={'login-logo'} />
      </div>
    </div>
  );
};

export { LoginComponent };
